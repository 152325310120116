import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/Na-czym-polega-filozofia-CRM.jpg";

export const query = graphql`
  query {
    zdjecie5: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const FilozofiaCRM = ({ data }) => {
  return (
    <Artykul
      title="Na czym polega filozofia CRM?"
      articleImage={obrazekArtykulu}
      keywords={["filozofia CRM"]}
      articleImageAlt="Na czym polega filozofia CRM"
      metaTitle="Filozofia CRM – na czym polega i jak ją wdrożyć w firmie?"
      metaDescription="✅ Czym jest filozofia CRM w przedsiębiorstwie? Jak ją wdrożyć? • QuickCRM - 30 dni za darmo!"
    >
      <h2>
        Zarządzanie relacjami z klientem (CRM) to bardzo ważne zadanie dla
        każdej firmy.
      </h2>
      <p>
        Mówi się, że jest to jeden z kluczowych czynników, determinujących
        sukces przedsiębiorstwa. Wciąż jednak w wielu firmach relacje z klientem
        nie są planowane i budowane wokół jednej, ogólnie przyjętej procedury.
        Warto się zastanowić nad wprowadzeniem takich zasad, ponieważ przynoszą
        określone korzyści po obu stronach procesu sprzedaży. Czym jest w takim
        razie filozofia CRM i na czym polega jej wdrożenie? Jakie korzyści płyną
        ze sprawnego zarządzania relacjami z klientem?
      </p>
      <h3>Założenia</h3>
      <p>
        Podstawy filozofii CRM opierają się na założeniu, że najważniejszym
        elementem i celem prowadzenia działalności jest satysfakcja klienta.
        Wszystkie działania firmy (głównie działów sprzedaży i marketingu)
        powinny być skupione na osiągnięciu tego celu.
      </p>
      <h3>Definicja CRM</h3>
      <p>
        Filozofia CRM to termin, który nie ma jednej, ogólnie przyjętej
        definicji. Najczęściej na pytanie czym jest CRM odpowiada się, że są to
        zasady czy procedury przyjęte w firmie (to właśnie filozofia CRM) oraz
        narzędzia (najczęściej określone funkcje systemu CRM), które pozwalają
        wdrożyć i egzekwować te zasady. W przypadku narzędzi najczęściej
        wykorzystuje się system CRM, czyli oprogramowanie dla firmy, które
        pozwala na obsługę procesu sprzedaży i zarządzanie pracą działu
        handlowego.
      </p>
      <h3>Filozofia CRM w praktyce</h3>
      <p>
        Wdrożenie założeń filozofii CRM wymaga od firmy zmiany podejścia do
        zarządzania informacjami o klientach. Aby obsługa w procesie sprzedaży
        zapewniała najwyższy poziom satysfakcji klienta (a o to przecież
        filozofia CRM zabiega), należy zadbać o odpowiednie przygotowanie osób,
        które za nią odpowiadają – przede wszystkim handlowców. Dzięki
        wykorzystaniu narzędzi CRM zyskują oni dostęp do cennych informacji o
        kliencie i historii jego współpracy z Twoją firmą. Pozwala to zachować
        najwyższą jakość obsługi, ponieważ możesz łatwo zaplanować pracę (nawet
        w przypadku nieobecności lub odejścia danego pracownika).
      </p>
      <h3>Jak wdrożyć CRM?</h3>
      <p>
        Filozofia CRM dla małej firmy może oznaczać dobry punkt wyjścia do
        wzrostu skali działania. Aby ją wdrożyć, warto skorzystać z
        oprogramowania, które Ci w tym pomoże. Założenie prostego systemu CRM za
        darmo nie wymaga nakładu czasowego ani dużych umiejętności, swój
        pierwszy system możesz mieć już w kilka sekund w wersji próbnej.
        Wprowadź do niego swoją bazę klientów, dowiedz się i pokaż handlowcom,
        jak z niego korzystać.
      </p>
      <h3>Filozofia CRM – korzyści</h3>
      <p>
        Warto pamiętać o tym, co ma finalnie przynieść wdrożenie systemu CRM i
        zasad zarządzania relacjami z klientem. Filozofia CRM powinna prowadzić
        firmę do:
        <li>wzrostu satysfakcji klientów,</li>
        <li>wzrostu efektywności pracy handlowców,</li>
        <li>łatwiejszej analizy i obsługi procesu sprzedaży,</li>
        <li>poprawy komunikacji wewnątrz firmy.</li>
        <br />W dłuższej skali filozofia CRM ma służyć podnoszeniu wolumenu
        sprzedaży, dzięki wprowadzaniu usprawnień w procesie pozyskiwania i
        obsługi klientów. System CRM pozwala z łatwością odnajdywać miejsca, w
        których usprawnienia są konieczne — to tak zwane wąskie gardła.
        Korzyści, które może uzyskać firma będą zależne od wielu czynników.
        Jednym z nich jest wybór odpowiedniego dostawcy systemu CRM. Jeśli nie
        potrafisz wybrać, jaki CRM będzie dla Ciebie idealny, zapoznaj się z{" "}
        <Link to="/jaki-crm/">tym artykułem.</Link>
      </p>
      <Link to="/demo-crm/">
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Testuj System CRM"
          alt="DEMO CRM - Filozofia CRM"
          fluid={data.zdjecie5.childImageSharp.fluid}
        />
      </Link>
    </Artykul>
  );
};

export default FilozofiaCRM;
